<app-modal-header class="border-b-2 border-theme-500">
  <div class="flex items-center">
    <svg-icon
      class="mr-5 w-10 text-theme-500"
      src="assets/icons/icon-gestor-avaliacoes.svg"
      alt="Materiais Didaticos"
    ></svg-icon>
    <h1 class="hidden text-sm font-semibold leading-tight text-gray-800 md:grid" translate>
      gestor-avaliacoes.e-class
      <br />
      <b class="text-lg text-black" translate>gestor-avaliacoes.prova</b>
    </h1>
    <hr app-modal-divider class="hidden md:block" />

    <div class="flex items-baseline gap-2">
      <h1 class="flex text-md text-gray-700 md:text-3xl" translate>Exportar dados</h1>
    </div>
  </div>
</app-modal-header>

<div app-modal-content class="h-auto w-full md:w-[80vw] lg:w-[65vw] 2xl:w-[40vw]">
  <form [formGroup]="form">
    <div class="grid w-full lg:flex lg:justify-between lg:gap-4">
      <app-form-field class="w-full lg:w-6/12">
        <label app-label class="font-semibold">Tipo</label>
        <ng-select
          app-select
          [items]="options"
          formControlName="export_type"
          bindLabel="title"
          bindValue="id"
          [clearable]="false"
          [searchable]="false"
        >
        </ng-select>
      </app-form-field>

      <app-form-field class="w-full lg:w-6/12">
        <label app-label class="font-semibold">Mínimo de preenchimento</label>
        <input app-input formControlName="min_fill" currencyMask [options]="scoreConfig" />
      </app-form-field>
    </div>
    <div app-form-field class="w-full">
      <label app-label class="font-semibold" translate>Avaliações</label>
      <ng-select
        class="pb-4"
        app-select
        [items]="applications$ | async"
        bindValue="uuid"
        formControlName="uuids"
        placeholder="Selecione"
        [notFoundText]="'geral.nao-encontrado' | translate"
        [searchable]="true"
        [multiple]="true"
      >
        <ng-template ng-label-tmp let-item="item" let-clear="clear">
          <div class="w-auto pr-2">
            <span class="ng-value-icon left" (click)="clear(item)" aria-hidden="true">×</span>
            <span class="pl-2 text-sm font-semibold">{{ item?.avaliacao?.titulo }}</span>
          </div>
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
          <div class="w-auto">
            <span [ngOptionHighlight]="search" class="pl-2 text-sm font-semibold">{{ item.avaliacao.titulo }}</span>
            <div class="pl-1 text-xs font-normal text-base-gray-400">
              {{ item.avaliacao.avaliacao_questoes_count }} questoes - {{ item.data_inicio | date : 'short' }} até
              {{ item.data_fim | date : 'short' }}
            </div>
          </div>
        </ng-template>
      </ng-select>
    </div>

    <div class="mb-5 mt-5 flex justify-end">
      <button
        app-icon-button
        type="button"
        (click)="onExport()"
        class="w-48 gap-3 bg-blue-500 uppercase text-theme-contrast"
        translate
      >
        <svg-icon class="flex h-5 w-5 text-white" src="assets/icons/icon-download.svg"></svg-icon>
        Download
      </button>
    </div>
  </form>
</div>
