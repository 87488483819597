import { DIALOG_DATA, Dialog, DialogRef } from '@angular/cdk/dialog';
import { Component, Inject } from '@angular/core';
import { CertificadoService } from '@app/core/services/certificado.service';
import { notNull, selectedEntityActive } from '@app/shared';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { BehaviorSubject, EMPTY, Observable, filter, map, switchMap } from 'rxjs';
import { CertificateService } from '../../../../core/services/certificate.service';
import { ModalCertificateFormComponent } from '../modal-certificate-form/modal-certificate-form.component';

export interface ModalCertificadoData {
  canSelect: boolean;
  labelCertificado: string;
}

const DEFAULT_DATA = {
  canSelect: true,
  labelCertificado: 'trilhas.certificado.clique-para-selecionar',
};
@Component({
  selector: 'app-modal-certificate',
  templateUrl: './modal-certificate.component.html',
  styleUrls: ['./modal-certificate.component.scss'],
  styles: ``,
})
export class ModalCertificateComponent {
  public certificados$ = new BehaviorSubject<any>(undefined);
  public certificateData: ModalCertificadoData = {} as any;
  public entities$: Observable<any>;
  public modal: DialogRef<unknown, ModalCertificateComponent>;
  constructor(
    @Inject(DIALOG_DATA) public data: any,
    private _certificateService: CertificateService,
    private _certificadoService: CertificadoService,
    private store: Store<AppState>,
    private dialogRef: DialogRef,
    private dialog: Dialog,
  ) {
    this.loadListener();
    this._certificadoService.setShowCertificadoDefault(true);
  }

  public onFormat(certificado: any) {
    return {
      id: certificado.id,
      title: certificado.titulo,
      text: certificado.texto,
      backgroundImage: certificado.background || 'assets/images/certificate-default/certificado-padrao.jpg',
      assinaturas: [
        {
          assinatura: certificado.file_assinatura_1,
          nome: certificado.nome_assinatura_1,
          linha_1: certificado.linha_1_assinatura_1,
          linha_2: certificado.linha_2_assinatura_1,
        },
        {
          assinatura: certificado.file_assinatura_2,
          nome: certificado.nome_assinatura_2,
          linha_1: certificado.linha_1_assinatura_2,
          linha_2: certificado.linha_2_assinatura_2,
        },
        {
          assinatura: certificado.file_assinatura_3,
          nome: certificado.nome_assinatura_3,
          linha_1: certificado.linha_1_assinatura_3,
          linha_2: certificado.linha_2_assinatura_3,
        },
      ],
    };
  }

  public get safeData(): ModalCertificadoData {
    return { ...DEFAULT_DATA, ...this.certificateData };
  }

  public onSelect(certificado: any) {
    this._certificateService.passCertificate(certificado);
    this.dialogRef.close();
  }

  public edit(certificado: any) {
    this.onClose();
    this.dialog
      .open(ModalCertificateFormComponent, {
        hasBackdrop: true,
        data: this.onFormat(certificado),
      })
      .closed.pipe(notNull())
      .subscribe();
  }

  public onClose() {
    this.dialogRef.close();
  }

  public onCloseAdd() {
    this.routerCertificado();
  }

  private routerCertificado() {
    this.onClose();
    this.dialog
      .open(ModalCertificateFormComponent, {
        hasBackdrop: true,
      })
      .closed.pipe(notNull())
      .subscribe();
  }

  public onCloseModal() {
    this.dialogRef.close();
  }

  private loadListener() {
    this.store
      .select(AppSelectors.ActiveUser)
      .pipe(
        filter((user) => !!user),
        map((user) => selectedEntityActive(user)),
        switchMap((entities) => {
          if (entities && entities.length > 0) {
            return this._certificadoService.getAllCertificado(entities[0]);
          }
          return EMPTY;
        }),
        map((res) => (res?.ret === 1 ? res.data : [])),
      )
      .subscribe((values) => this.certificados$.next(values));
  }
}
