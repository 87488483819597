import { ChangeDetectionStrategy, Component, DestroyRef, Input, OnDestroy } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { notNull, selectedEntityActive } from '@app/shared';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { first } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { FormInstructionsReportComponent } from '../form-instructions-report/form-instructions-report.component';

@Component({
  selector: 'app-modal-body-report',
  templateUrl: './modal-body-report.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalBodyReportComponent implements OnDestroy {
  public readonly formInstructionsReportComponentRef = FormInstructionsReportComponent;
  public application$ = new BehaviorSubject<any>(undefined);
  public isNotEntity$ = new BehaviorSubject<boolean>(false);

  constructor(private _store: Store<AppState>, private _destroyRef: DestroyRef) {
    this._store
      .select(AppSelectors.ActiveUser)
      .pipe(takeUntilDestroyed(this._destroyRef), notNull())
      .subscribe((activeUser) => this.isNotEntity$.next(first(selectedEntityActive(activeUser))?.tipo !== 1));
  }

  @Input('application') public set setApplication(application) {
    this.application$.next(application);
  }

  public ngOnDestroy(): void {
    this.application$.complete();
  }
}
