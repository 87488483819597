import { SidenavCloseDirective } from './sidenav-close.directive';
import { SidenavContainerComponent } from './sidenav-container.component';
import { SidenavEndDirective } from './sidenav-end.directive';
import { SidenavGroupComponent } from './sidenav-group.component';
import { SidenavOuterDirective } from './sidenav-outer.directive';
import { SidenavStartDirective } from './sidenav-start.directive';
import { SidenavComponent } from './sidenav.component';

export * from './sidenav-group.component';
export * from './sidenav.component';
export * from './sidenav';

export const SIDENAV = [
  SidenavComponent,
  SidenavGroupComponent,
  SidenavCloseDirective,
  SidenavStartDirective,
  SidenavEndDirective,
  SidenavOuterDirective,
  SidenavContainerComponent,
];
