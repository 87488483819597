import { Injectable } from '@angular/core';
import { MenuItem, MenuRef } from './menu';

@Injectable({ providedIn: 'root' })
export class AssessmentsManagerMenu implements MenuRef {
  public identifier = 'assessmentsManager';

  public readonly menu: MenuItem[] = [
    () => ({
      title: 'menu.gestor-avaliacoes',
      url: '/eclass-prova',
      icon: 'icon-gestor-avaliacoes',
      dimension: 'h-7 w-7',
      permission: [1, 4],
      //   show: false,
    }),
  ];

  public readonly submenu: MenuItem[] = [
    () => ({
      title: 'breadcrumb.assessment-manager.avaliacoes',
      url: '/eclass-prova',
      icon: 'icon-course-edit',
      group: 'menu.gestor-avaliacoes',
      exact: true,
    }),
    () => ({
      title: 'breadcrumb.assessment-manager.minhas-questoes',
      url: '/eclass-prova/questoes',
      icon: 'icon-text',
      group: 'menu.gestor-avaliacoes',
      permission: [3, 15],
    }),
  ];
}
