import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, DestroyRef, Inject, OnDestroy } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { AlertService, AssessmentApplicationService, NotificationService } from '@app/core';
import { StatusEnum } from '@app/core/models/status.enum';
import { Resp } from '@app/core/services/api.service';
import { notNull, safeEmptyList, selectedEntityActive } from '@app/shared';
import { SidenavDirection } from '@app/shared/modules/template/components/sidenav/sidenav';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { format, isAfter, isBefore } from 'date-fns';
import { first } from 'lodash';
import { BehaviorSubject, Observable, finalize, take } from 'rxjs';

@Component({
  selector: 'app-modal-release-students',
  templateUrl: './modal-release-students.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalReleaseStudentsComponent implements OnDestroy {
  public readonly direction = SidenavDirection;
  public dataStudents$ = new BehaviorSubject<any[]>([]);
  public dataList$ = new BehaviorSubject<any[]>(undefined);
  public submitted$ = new BehaviorSubject<boolean>(undefined);
  public form: FormGroup;

  constructor(
    public dialogRef: DialogRef<boolean>,
    @Inject(DIALOG_DATA) public data: any,
    private _assessmentApplicationService: AssessmentApplicationService,
    private _formBuilder: FormBuilder,
    private _alertService: AlertService,
    private _notificationService: NotificationService,
    private _translateService: TranslateService,
    private _store: Store<AppState>,
    private _destroyRef: DestroyRef,
  ) {
    this.initForm();
    this._store
      .select(AppSelectors.ActiveUser)
      .pipe(takeUntilDestroyed(this._destroyRef), notNull())
      .subscribe((user) => this.form.get('entidade').setValue(first(selectedEntityActive(user))));
    this.dataStudents$.next(data.dataStudent);

    this.loadData();
  }

  public get minDate(): string | undefined {
    return this.data.application.pre_aplicacao === 1 ? this.data.application.data_inicio : undefined;
  }

  public get maxDate(): string | undefined {
    return this.data.application.pre_aplicacao === 1 ? this.data.application.data_fim : undefined;
  }

  public ngOnDestroy(): void {
    this.dataStudents$.complete();
    this.dataList$.complete();
    this.submitted$.complete();
    this.form.reset();
  }

  public onRemove(usuarioId) {
    this.dataStudents$.next(this.dataStudents$.value.filter((aluno) => aluno.usuario_id !== usuarioId));
  }

  public onSave() {
    this.form.get('users_id').setValue(this.dataStudents$.value.map((a) => a.usuario_id));

    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    const data = {
      ...this.form.value,
      liberar_data_inicio: format(this.form.value.liberar_data_inicio, 'yyyy-MM-dd HH:mm'),
      liberar_data_fim: format(this.form.value.liberar_data_fim, 'yyyy-MM-dd HH:mm'),
    };

    this._alertService
      .confirm({
        title: 'geral.atencao',
        message: 'gestor-avaliacoes.msg-confirmacao-liberacao',
      })
      .pipe(take(1), notNull())
      .subscribe(() => {
        this.observable(this._assessmentApplicationService.storeReleaseStudents(this.data.application.uuid, data));
        this.dataStudents$.next(undefined);
        this.form.reset();
      });
  }

  private loadData() {
    this._assessmentApplicationService
      .indexReleaseStudents(this.data.application.uuid)
      .pipe(takeUntilDestroyed(this._destroyRef), safeEmptyList(), notNull())
      .subscribe((data) => {
        if (data.length > 0) {
          this.dataList$.next(data);
        }
      });
  }

  private initForm() {
    this.form = this._formBuilder.group({
      liberar_data_inicio: [undefined, Validators.required],
      liberar_data_fim: [undefined, Validators.required],
      users_id: [undefined, Validators.required],
      entidade: undefined,
    });

    this.form.get('liberar_data_inicio').setValidators([Validators.required, this.validateDateStart]);
    this.form.get('liberar_data_fim').setValidators([Validators.required, this.validateDateEnd]);
  }

  private validateDateStart = (control: AbstractControl): ValidationErrors | null => {
    if (
      control.value &&
      control.parent.get('liberar_data_fim').value &&
      isAfter(new Date(control.value), new Date(control.parent.get('liberar_data_fim').value))
    ) {
      return { dateStart: true };
    }

    return null;
  };

  private validateDateEnd = (control: AbstractControl): ValidationErrors | null => {
    if (
      control.value &&
      control.parent.get('liberar_data_inicio').value &&
      isBefore(new Date(control.value), new Date(control.parent.get('liberar_data_inicio').value))
    ) {
      return { dateEnd: true };
    }

    return null;
  };

  private observable(observable: Observable<Resp<any>>) {
    this.submitted$.next(true);
    observable
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        finalize(() => this.submitted$.next(false)),
      )
      .subscribe((resp) => {
        if (resp.ret === StatusEnum.Inactive) {
          this._notificationService.error(this._translateService.instant('geral.erro-ao-salvar'));
        } else {
          this._notificationService.success(this._translateService.instant('geral.salvo-com-sucesso'));
          this.dialogRef.close(true);
        }
      });
  }
}
