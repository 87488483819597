<app-popover>
  <div class="grid h-auto px-2">
    <button
      app-outline-button
      (click)="onModalResolutionEvaluation()"
      class="group my-1 h-9 w-full min-w-[140px] !justify-start border-none bg-black/5 text-left text-black/80 hover:bg-base-status-warning hover:font-semibold hover:text-white"
    >
      <svg-icon
        class="mr-2 w-5 text-base-status-warning group-hover:text-white"
        src="assets/icons/icon-clipboard-check.svg"
      ></svg-icon>
      <span class="hidden group-hover:text-theme-contrast md:inline-block" translate>
        gestor-avaliacoes.ver-respostas
      </span>
    </button>

    <button
      *isAllow="[3, 999]"
      app-outline-button
      (click)="onAssessmentsImportFileComponent()"
      class="group my-1 h-9 w-full min-w-[140px] !justify-start border-none bg-black/5 text-left text-black/80 hover:bg-purple-400 hover:font-semibold hover:text-white"
    >
      <svg-icon
        class="mr-2 w-5 text-purple-400 group-hover:text-white"
        src="assets/icons/icon-upload-small.svg"
      ></svg-icon>
      <span class="hidden group-hover:text-theme-contrast md:inline-block" translate>
        gestor-avaliacoes.importacao-arquivos
      </span>
    </button>

    <button
      *isAllow="[3, 999]"
      app-outline-button
      (click)="onModalAnswersAuditComponent()"
      class="group my-1 h-9 w-full min-w-[140px] !justify-start border-none bg-black/5 text-left text-black/80 hover:bg-red-400 hover:font-semibold hover:text-white"
    >
      <svg-icon
        class="mr-2 w-5 text-base-status-danger group-hover:text-white"
        src="assets/icons/icon-clipboard-check.svg"
      ></svg-icon>
      <span class="hidden group-hover:text-theme-contrast md:inline-block" translate>
        gestor-avaliacoes.auditoria-respostas
      </span>
    </button>

    <button
      app-outline-button
      (click)="onAssessmentsPrintSettingsComponent()"
      class="group my-1 h-9 w-full min-w-[140px] !justify-start border-none bg-black/5 text-left text-black/80 hover:bg-theme-500 hover:font-semibold hover:text-white"
    >
      <svg-icon
        class="mr-2 w-5 text-theme-500 group-hover:text-white"
        src="assets/icons/icon-clipboard-check.svg"
      ></svg-icon>
      <span class="hidden group-hover:text-theme-contrast md:inline-block" translate>
        gestor-avaliacoes.configuracoes-impressao
      </span>
    </button>

    <button
      *isAllow="[3, 999]"
      app-outline-button
      (click)="onCreateResponseCard()"
      class="group my-1 h-9 w-full min-w-[140px] !justify-start border-none bg-black/5 text-left text-black/80 hover:bg-theme-500 hover:font-semibold hover:text-white"
    >
      <svg-icon
        class="mr-2 w-5 text-theme-500 group-hover:text-white"
        src="assets/icons/icon-clipboard-check.svg"
      ></svg-icon>
      <span class="hidden group-hover:text-theme-contrast md:inline-block" translate>
        gestor-avaliacoes.gerar-cartao
      </span>
    </button>

    <button
      *isAllow="[3, 999]"
      app-outline-button
      (click)="onUploadFiles()"
      class="group my-1 h-9 w-full min-w-[140px] !justify-start border-none bg-black/5 text-left text-black/80 hover:bg-purple-400 hover:font-semibold hover:text-white"
    >
      <svg-icon
        class="mr-2 w-5 text-purple-400 group-hover:text-white"
        src="assets/icons/icon-upload-small.svg"
      ></svg-icon>
      <span class="hidden group-hover:text-theme-contrast md:inline-block" translate>
        gestor-avaliacoes.fazer-upload
      </span>
    </button>

    <ng-container *ngIf="!isEntity">
      <span class="mt-3 text-sm font-semibold text-gray-400" translate> gestor-avaliacoes.exportacao </span>
      <hr app-divider />

      <button
        app-outline-button
        (click)="onExportTCT()"
        class="group my-1 h-9 w-full min-w-[140px] !justify-start border-none bg-black/5 text-left text-black/80 hover:bg-base-status-info hover:font-semibold hover:text-white"
      >
        <svg-icon
          class="mr-2 w-5 text-base-status-info group-hover:text-white"
          src="assets/icons/icon-download.svg"
        ></svg-icon>
        <span class="hidden group-hover:text-theme-contrast md:inline-block" translate>
          gestor-avaliacoes.relatorio-tct
        </span>
      </button>
      <button
        app-outline-button
        (click)="onExportAttemptStudents()"
        class="group my-1 h-9 w-full min-w-[140px] !justify-start border-none bg-black/5 text-left text-black/80 hover:bg-base-status-info hover:font-semibold hover:text-white"
      >
        <svg-icon
          class="mr-2 w-5 text-base-status-info group-hover:text-white"
          src="assets/icons/icon-download.svg"
        ></svg-icon>
        <span class="hidden group-hover:text-theme-contrast md:inline-block" translate>
          gestor-avaliacoes.relatorio-dicotomizado
        </span>
      </button>
      <button
        app-outline-button
        (click)="onExportGabaritoStudents()"
        class="group my-1 h-9 w-full min-w-[140px] !justify-start border-none bg-black/5 text-left text-black/80 hover:bg-base-status-info hover:font-semibold hover:text-white"
      >
        <svg-icon
          class="mr-2 w-5 text-base-status-info group-hover:text-white"
          src="assets/icons/icon-download.svg"
        ></svg-icon>
        <span class="hidden group-hover:text-theme-contrast md:inline-block" translate>
          gestor-avaliacoes.gabarito-alunos
        </span>
      </button>
    </ng-container>
  </div>
</app-popover>
