<ng-select
  app-select
  [placeholder]="'materiais-didaticos.nivel-ensino' | translate"
  [multiple]="multiple"
  (change)="onChange($event)"
  (focus)="onFocus()"
  [formControl]="ctrl"
  class="w-full"
  [items]="data$ | async"
  bindLabel="descricao"
  bindValue="id"
>
</ng-select>
