<div app-modal-content class="h-[80vh] !p-0 md:h-[90vh] lg:w-[95vw] xl:h-[70vh] xl:w-[65vw]">
  <app-sidenav-group>
    <form [formGroup]="form">
      <ng-template app-sidenav-start [opened]="true" class="rounded-bl-lg">
        <div class="h-full bg-white p-6">
          <span class="flex w-full text-xl font-semibold" translate> gestor-avaliacoes.diagramacao</span>
          <hr app-divider class="my-5" />
          <div class="grid w-full">
            <app-form-field class="w-full">
              <label app-label class="font-semibold" translate> gestor-avaliacoes.numero-colunas </label>
              <ng-select
                app-select
                [clearable]="false"
                [searchable]="false"
                formControlName="columns"
                [items]="printTypesColumns"
                bindLabel="name"
                bindValue="id"
                [placeholder]="'gestor-avaliacoes.selecione-margem' | translate"
              >
                <ng-template ng-option-tmp let-index="index" let-item="item">
                  <div class="flex items-center gap-2">
                    <img height="20" width="20" [src]="item.icon" />
                    <span>{{ item.name }}</span>
                  </div>
                </ng-template>

                <ng-template ng-option-tmp let-item="item" let-index="index">
                  <div class="!flex !items-center !gap-2">
                    <img height="20" width="20" [src]="item.icon" /> <span>{{ item.name }}</span>
                  </div>
                </ng-template>
              </ng-select>
            </app-form-field>

            <app-form-field class="w-full">
              <label app-label class="font-semibold" translate>gestor-avaliacoes.fonte</label>
              <ng-select
                app-select
                [clearable]="false"
                [searchable]="false"
                formControlName="font_family"
                bindLabel="name"
                bindValue="id"
                [items]="printTypesFont"
                [placeholder]="'gestor-avaliacoes.selecione-fonte' | translate"
              >
                <ng-template ng-label-tmp let-item="item">
                  <span [ngClass]="getFontClass(item.name)">{{ item.name }}</span>
                </ng-template>
                <ng-template ng-option-tmp let-item="item">
                  <span [ngClass]="getFontClass(item.name)">{{ item.name }}</span>
                </ng-template>
              </ng-select>
            </app-form-field>

            <app-form-field class="w-full">
              <label app-label class="font-semibold" translate> gestor-avaliacoes.tamanho-fonte </label>
              <ng-select
                app-select
                [clearable]="false"
                [searchable]="false"
                formControlName="font_size"
                bindLabel="name"
                bindValue="id"
                [items]="fontSizes"
                [placeholder]="'gestor-avaliacoes.selecione-tamanho-fonte' | translate"
              >
                <ng-template ng-option-tmp let-item="item">
                  <span [style]="'font-size:' + item.id">{{ item.name }}</span>
                </ng-template>

                <ng-template ng-label-tmp let-index="index" let-item="item">
                  <span [style]="'font-size:' + item.id">{{ item.name }}</span>
                </ng-template>
              </ng-select>
            </app-form-field>

            <app-form-field class="w-full">
              <label app-label class="font-semibold" translate> gestor-avaliacoes.margem </label>
              <ng-select
                app-select
                [clearable]="false"
                [searchable]="false"
                formControlName="margin"
                [items]="printTypesMargin"
                bindLabel="name"
                bindValue="id"
                [placeholder]="'gestor-avaliacoes.selecione-margem' | translate"
              >
                <ng-template ng-label-tmp let-index="index" let-item="item">
                  <div class="flex items-center gap-2">
                    <img height="20" width="20" [src]="item.icon" />
                    <span>{{ item.name }}</span>
                  </div>
                </ng-template>

                <ng-template ng-option-tmp let-item="item" let-index="index">
                  <div class="!flex !items-center !gap-2">
                    <img height="20" width="20" [src]="item.icon" /> <span>{{ item.name }}</span>
                  </div>
                </ng-template>
              </ng-select>
            </app-form-field>

            <ng-container *ngIf="identifiers$ | async as identifiers">
              <app-form-field class="w-full">
                <label app-label class="flex font-semibold" translate>
                  gestor-avaliacoes.cabecalho
                  <svg-icon
                    class="ml-2 flex !h-4 !w-4 cursor-pointer !text-gray-500"
                    app-tooltip
                    [tooltip]="'gestor-avaliacoes.aplicacao.itens-cadastrados-hub' | translate"
                    src="assets/icons/icon-interrogation.svg"
                  ></svg-icon>
                </label>
                <ng-select
                  app-select
                  [searchable]="false"
                  formControlName="header_id"
                  [items]="identifiers.header"
                  [notFoundText]="'Não encontrado'"
                  bindLabel="titulo"
                  bindValue="id"
                  [placeholder]="'gestor-avaliacoes.selecione-cabecalho' | translate"
                >
                </ng-select>
              </app-form-field>

              <app-form-field class="w-full">
                <label app-label class="flex font-semibold" translate>
                  gestor-avaliacoes.rodape
                  <svg-icon
                    class="ml-2 flex !h-4 !w-4 cursor-pointer !text-gray-500"
                    app-tooltip
                    [tooltip]="'gestor-avaliacoes.aplicacao.itens-cadastrados-hub' | translate"
                    src="assets/icons/icon-interrogation.svg"
                  ></svg-icon>
                </label>
                <ng-select
                  app-select
                  [searchable]="false"
                  formControlName="footer_id"
                  [notFoundText]="'Não encontrado'"
                  [items]="identifiers.footer"
                  bindLabel="titulo"
                  bindValue="id"
                  [placeholder]="'gestor-avaliacoes.selecione-rodape' | translate"
                >
                </ng-select>
              </app-form-field>
            </ng-container>

            <div class="grid w-full md:flex">
              <app-form-field class="w-full">
                <label app-label class="flex font-semibold" translate>
                  gestor-avaliacoes.peso-questoes
                  <svg-icon
                    app-tooltip
                    [tooltip]="'gestor-avaliacoes.mostra-peso' | translate"
                    class="ml-3 flex h-5 w-5"
                    src="assets/icons/icon-help.svg"
                  ></svg-icon
                ></label>
                <app-switch formControlName="show_score"></app-switch>
              </app-form-field>

              <app-form-field *isAllow="[3, 999]" class="w-full">
                <label app-label class="flex font-semibold" translate>
                  gestor-avaliacoes.exibir-instituicoes
                  <svg-icon
                    app-tooltip
                    [tooltip]="'gestor-avaliacoes.tooltip-oculta-instituicao' | translate"
                    class="ml-3 flex h-5 w-5"
                    src="assets/icons/icon-help.svg"
                  ></svg-icon
                ></label>
                <app-switch formControlName="show_reference"></app-switch>
              </app-form-field>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template app-sidenav-outer>
        <div class="h-full overflow-y-auto p-5">
          <div app-modal-content class="grid h-full !p-0">
            <div
              class="h-[35rem] overflow-y-auto p-4 shadow-[0_0px_15px_-2px_rgb(0,0,0,0.2)] md:h-[80rem] lg:h-[50rem]"
            >
              <iframe class="h-full w-full" frameborder="0" [src]="urlParam$ | async | safe : 'resourceUrl'"></iframe>
            </div>
          </div>
        </div>

        <div class="absolute bottom-0 w-full bg-white p-8 shadow-up">
          <div class="flex items-center justify-end">
            <button
              app-button
              (click)="onPrintQuest()"
              class="bg-base-mantis-500 text-white hover:bg-base-mantis-500"
              type="button"
            >
              <span translate> gestor-avaliacoes.imprimir-avaliacao </span>
            </button>
          </div>
        </div>
      </ng-template>
    </form>
  </app-sidenav-group>
</div>
