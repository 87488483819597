import { Injectable } from '@angular/core';
import { portalConst } from '@app/config';
import { Collection } from '@app/modules/learning-trails/models';
import { TrailsService } from '@app/modules/learning-trails/services';
import { Observable } from 'rxjs';
import { ApiService, Resp } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class LearningTrailsService {
  constructor(private api: ApiService, private trailsService: TrailsService) {}

  public courses(): Observable<Resp<Collection[]>> {
    return this.api.get(`${portalConst.api.trilhas}/site/v1/cursos`, this.checkUcIvca());
  }

  public showcase(entity: any): Observable<Resp<Collection[]>> {
    return this.api.get(`${portalConst.api.trilhas}/site/v1/cursos/vitrine`, { ...entity, ...this.checkUcIvca() });
  }

  public showcaseIndividual(courseId: number): Observable<Resp<Collection[]>> {
    return this.api.get(`${portalConst.api.trilhas}/site/v1/cursos/vitrine/${courseId}`);
  }

  public showcaseForReport(entity: any): Observable<Resp<Collection[]>> {
    return this.api.get(`${portalConst.api.trilhas}/v1/cursos/vitrines`, { entidade: entity, relatorio: 1 });
  }

  public reorderCourses(collectionId: number, courses: any): Observable<Resp<any>> {
    return this.api.put(`${portalConst.api.trilhas}/v1/cursos/reordenar`, {
      colecao_id: collectionId,
      cursos: courses,
    });
  }

  public courseReportData(courseSlug: string, entity: any): Observable<Resp<Collection[]>> {
    return this.api.get(`${portalConst.api.trilhas}/v1/cursos/${courseSlug}/relatorios/data`, { entidade: entity });
  }

  public courseReportEvent(
    courseSlug: string,
    eventId: number,
    entities: any,
    group?: any,
    learningTrailId?: number,
  ): Observable<Resp<Collection[]>> {
    const processedEntities = entities.map(({ nome, ...rest }) => rest);
    const params: any = { entidades: JSON.stringify(processedEntities) };
    if (group) params['turma'] = group;
    if (learningTrailId) params['trilha_id'] = learningTrailId;
    return this.api.get(`${portalConst.api.trilhas}/v1/cursos/${courseSlug}/relatorios/evento/${eventId}`, params);
  }

  public eventEntities(eventId: number, entity: any): Observable<Resp<Collection[]>> {
    return this.api.get(`${portalConst.api.trilhas}/v1/eventos/${eventId}/entidades`, { entidade: entity });
  }

  public entityGroups(employeeId: number, defaultFunction: number, entities: any): Observable<Resp<any>> {
    const processedEntities = entities.map(({ nome, ...rest }) => rest);
    return this.api.get(`${portalConst.api.trilhas}/v1/turmas-professor`, {
      funcionario_id: employeeId,
      entidades: JSON.stringify(processedEntities),
      funcao_padrao: defaultFunction,
    });
  }

  public entityUsers(
    courseSlug: string,
    eventId: number,
    entities: any,
    group?: any,
    learningTrailId?: number,
    subdivisionId?: number,
  ): Observable<Resp<any>> {
    const processedEntities = entities.map(({ nome, ...rest }) => rest);
    return this.api.get(`${portalConst.api.trilhas}/v1/cursos/${courseSlug}/relatorios/evento/${eventId}/usuarios`, {
      entidades: JSON.stringify(processedEntities),
      turma: JSON.stringify(group),
      trilha_id: learningTrailId,
      subdivisao_id: subdivisionId,
    });
  }

  public eventDetails(eventId: number): Observable<Resp<any>> {
    return this.api.get(`${portalConst.api.trilhas}/v1/evento/${eventId}`);
  }

  public subdivisions(slug: string, evento_id?: number): Observable<Resp<any>> {
    return this.api.get(`${portalConst.api.trilhas}/site/v1/curso/${slug}`, { evento_id });
  }

  public studio(entidade): Observable<Resp<any>> {
    return this.api.get(`${portalConst.api.trilhas}/v1/curso`, { ...entidade, ...this.checkUcIvca() });
  }

  public categorys(entity: any, events?: any): Observable<any> {
    return this.api.get(`${portalConst.api.trilhas}/v1/categorias`, {
      entidade: entity,
      vitrine: events,
      ...this.checkUcIvca(),
    });
  }

  public coursesByCollection(collection_id: number, entity_id: number, type: number): Observable<any> {
    return this.api.get(`${portalConst.api.trilhas}/v1/cursos/${collection_id}/${entity_id}/${type}`);
  }

  public show(slug: string): Observable<Resp<any>> {
    return this.api.get(`${portalConst.api.trilhas}/v1/trilha/${slug}`);
  }

  public store(data: any): Observable<Resp<any>> {
    return this.api.post(`${portalConst.api.trilhas}/v1/trilha`, data);
  }

  public update(id: number, data: any): Observable<Resp<any>> {
    return this.api.put(`${portalConst.api.trilhas}/v1/trilha/${id}`, data);
  }

  public delete(id: number): Observable<Resp<any>> {
    return this.api.delete(`${portalConst.api.trilhas}/v1/trilha/${id}`);
  }

  private checkUcIvca(): any {
    if (this.trailsService.uc$.value) return { uc: true };
    if (this.trailsService.ivca$.value) return { ivca: true };
    return {};
  }
}
