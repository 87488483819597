<app-modal-header class="border-b-2 border-theme-500">
  <div class="grid w-full gap-5 md:flex">
    <div class="flex">
      <svg-icon
        class="w-10 text-theme-500"
        src="assets/icons/icon-gestor-avaliacoes.svg"
        alt="Materiais Didaticos"
      ></svg-icon>
      <h1 class="ml-3 text-sm font-semibold leading-tight text-gray-800" translate>
        gestor-avaliacoes.e-class
        <br />
        <b class="text-lg text-black" translate>gestor-avaliacoes.prova</b>
      </h1>
    </div>

    <hr app-modal-divider />
    <ng-container *ngIf="application$ | async as application">
      <div class="grid w-full">
        <div class="gap-2 md:flex">
          <div class="text-black">
            <span class="whitespace-nowrap font-semibold"> {{ application.avaliacao.titulo }} </span>
          </div>
          <div>
            <span
              *ngIf="application.avaliacao.tipo_gerenciada_main?.id"
              class="rounded-full bg-modules-eclass-200 px-2 text-sm text-black"
              >{{ application.avaliacao.tipo_gerenciada_main.descricao }}</span
            >
          </div>
        </div>
        <span class="font-light"
          >{{ 'gestor-avaliacoes.resolvido-por' | translate }}
          {{ application?.aplicacao_tentativas[0]?.userAttempt || 0 }} {{ 'geral.de' | translate }}
          {{ application?.total_publico_alvo }} {{ 'gestor-avaliacoes.participantes' | translate }}</span
        >
      </div>
      <div class="flex w-full justify-end gap-2 md:w-3/12 lg:w-8/12 xl:w-5/12">
        <div class="flex w-full">
          <button
            app-flat-button
            (click)="onPreviewAssessment(application.uuid)"
            class="w-full bg-base-status-info px-5 uppercase text-white"
            type="button"
            translate
          >
            <svg-icon class="flex h-6 w-6" src="assets/icons/icon-view-fine.svg"></svg-icon>
            <span class="ml-2 hidden lg:flex" translate> gestor-avaliacoes.caixa-alta.pre-visualizar </span>
          </button>
        </div>
        <div *ngIf="candEdit$ | async" class="flex w-full">
          <button
            app-flat-button
            (click)="onEdit(application.uuid)"
            class="w-full bg-base-status-warning px-5 uppercase text-white"
            type="button"
            translate
          >
            <svg-icon class="flex h-6 w-6" src="assets/icons/icon-pencil.svg"></svg-icon>
            <span class="ml-2 hidden lg:flex" translate> gestor-avaliacoes.caixa-alta.editar </span>
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</app-modal-header>
