<ng-container *ngIf="data$ | async as data">
  <div class="h-full w-full">
    <ng-container *ngIf="!!data?.html; else fileViewTemplate">
      <div class="bg-white p-3">
        <app-text-editor [readOnly]="true" [value]="data.html"></app-text-editor>
      </div>
    </ng-container>
    <ng-template #fileViewTemplate>
      <ng-container [ngSwitch]="data?.type">
        <div *ngSwitchCase="'image'">
          <div class="flex items-center justify-center">
            <img class="max-h-[80vh] w-full max-w-[80vw]" [src]="data?.url" />
          </div>
        </div>
        <div class="flex h-full w-full" *ngSwitchCase="'url'">
          <ng-container *ngIf="isMobile(data?.url); else notMobile">
            <div class="flex w-full flex-col items-center">
              <span class="mb-4 text-center">{{ data?.name }}</span>
              <button
                type="button"
                class="flex min-h-[5rem] w-[60%] items-center justify-center rounded-full bg-blue-400 text-white"
                (click)="onOpenPdf(data?.url)"
              >
                {{ 'geral.abrir-pdf' | translate }}
              </button>
            </div>
          </ng-container>
          <ng-template #notMobile>
            <ngx-doc-viewer class="w-full" [url]="data?.url" [viewer]="data?.type"> </ngx-doc-viewer>
          </ng-template>
        </div>
        <div class="flex h-full w-full" *ngSwitchCase="'office'">
          <ngx-doc-viewer class="w-full" [url]="data?.url" [viewer]="data?.type"> </ngx-doc-viewer>
        </div>
        <div class="flex h-full w-full" *ngSwitchCase="'google'">
          <ngx-doc-viewer class="w-full" [url]="data?.url" [viewer]="data?.type"> </ngx-doc-viewer>
        </div>
        <div *ngSwitchCase="'audio'" class="flex h-[30vh] items-center justify-center bg-theme-500">
          <audio class="w-[50vw]" [src]="data?.url" controls>
            <source [src]="data?.url" type="audio/mpeg" />
          </audio>
        </div>
        <div *ngSwitchCase="'iframe'">
          <iframe
            class="my-10 h-[calc(100vw/2.3)] max-h-[80vh] w-full"
            [src]="data?.url | safe : 'resourceUrl'"
            allowfullscreen
            autoplay="true"
            frameborder="0"
          ></iframe>
        </div>
      </ng-container>
    </ng-template>
  </div>
</ng-container>
