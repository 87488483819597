import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DefaultService } from '@app/core/services';
import { notNull, selectedEntityActive } from '@app/shared/utils';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { head } from 'lodash';
import { BehaviorSubject, Observable, Subject, combineLatest, map, switchMap, takeUntil } from 'rxjs';
import { TypeBook } from '../../../modules/educational-materials/utils/type-book.model';

@Component({
  selector: 'app-select-default-subject',
  templateUrl: './select-default-subject.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectDefaultSubjectComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectDefaultSubjectComponent implements OnDestroy, ControlValueAccessor {
  @Input() public multiple = true;
  @Input() public label: boolean = true;
  @Input() public strictUser: boolean = false;
  @Output() public change = new EventEmitter();
  public data$: Observable<any[]>;
  public readonly type = TypeBook;
  public ctrl = new FormControl([]);
  public countryId$ = new BehaviorSubject<any>(undefined);
  public nivelId$ = new BehaviorSubject<any>(undefined);
  public entities: any;
  private _destroy$ = new Subject<void>();

  constructor(private store: Store<AppState>, private defaultService: DefaultService) {
    const activeUser$ = this.store.select(AppSelectors.ActiveUser).pipe(takeUntil(this._destroy$), notNull());
    this.data$ = combineLatest({ countryId: this.countryId$, activeUser: activeUser$ }).pipe(
      switchMap(({ countryId, activeUser }) => {
        const entity = selectedEntityActive(activeUser);
        return this.defaultService.disciplines(countryId || activeUser.pais_id, head(entity), this.strictUser);
      }),
      map((res) => res?.data || (this.multiple ? [] : undefined)),
    );
  }

  @Input()
  public set countryId(value: number) {
    this.countryId$.next(value);
  }

  @Input()
  public set nivelId(value: number) {
    this.nivelId$.next(value);
  }

  @Input('entities')
  public set setEntities(value: any) {
    this.entities = value;
    this.loadData();
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public writeValue(obj: any): void {
    if (this.ctrl) {
      this.ctrl.setValue(obj, { emitEvent: false });
    }
  }

  public registerOnChange(fn: any): void {
    this.changeValue = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
    this.ctrl.markAllAsTouched();
  }

  public setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.ctrl.disable();
    } else {
      this.ctrl.enable();
    }
  }

  public onFocus() {
    this.onTouched();
  }

  public onChange(value: any) {
    const defaultValue = this.multiple ? [] : undefined;
    this.change.emit(value.id || defaultValue);
    this.changeValue(value.id || defaultValue);
  }

  private loadData() {
    this.data$ = this.store.select(AppSelectors.ActiveUser).pipe(
      takeUntil(this._destroy$),
      notNull(),
      switchMap((user) => {
        const entity = this.entities ?? head(selectedEntityActive(user));
        return this.defaultService.disciplines(user.pais_id, JSON.stringify(entity), this.strictUser);
      }),
      map((res) => {
        const data = res?.data || (this.multiple ? [] : undefined);

        return data;
      }),
    );
  }

  private onTouched = () => true;
  private changeValue = (_: any) => true;
}
