import { ChangeDetectionStrategy, Component, DestroyRef, Inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { AssessmentApplicationService, NotificationService } from '@app/core';
import { AssessmentApplicationMode, GenericModel } from '@app/core/models';
import { safeEmpty } from '@app/shared';
import { SIDENAV_DATA } from '@app/shared/modules/template/components/sidenav';
import { TranslateService } from '@ngx-translate/core';
import { uniq } from 'lodash';
import { take } from 'rxjs';

@Component({
  selector: 'app-form-instructions-report',
  templateUrl: './form-instructions-report.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormInstructionsReportComponent {
  public readonly assessmentApplicationMode = AssessmentApplicationMode;
  public statusCtrl = new FormControl();
  public readonly applicationModes: GenericModel[] = [
    {
      id: AssessmentApplicationMode.Online,
      description: this._translateService.instant('gestor-avaliacoes.modos.online'),
    },
    {
      id: AssessmentApplicationMode.Impressa,
      description: this._translateService.instant('gestor-avaliacoes.modos.impressa'),
    },
    {
      id: AssessmentApplicationMode.Hibrido,
      description: this._translateService.instant('gestor-avaliacoes.modos.hibrido'),
    },
  ];

  constructor(
    private _assessmentApplicationService: AssessmentApplicationService,
    private _destroyRef: DestroyRef,
    private _notificationService: NotificationService,
    private _translateService: TranslateService,
    @Inject(SIDENAV_DATA) public data: any,
  ) {
    this.statusCtrl.valueChanges
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((status) => this.changeStatus(status));

    this.statusCtrl.setValue(!!this.data.application.status, { emitEvent: false });
  }

  public get targetUsers(): string[] {
    return this.data.application.publico_alvo
      .map((p) => (!!p.user_id ? `${p.user.name} ${p.user.sobrenome}` : undefined))
      .filter((p) => p);
  }

  public get targetGroups(): string[] {
    return this.data.application.publico_alvo
      .map((p) => (!!p.turma_id ? p.turma.turma_descricao : undefined))
      .filter((p) => p);
  }

  public get targetDefaultGrades(): string[] {
    return uniq(
      this.data.application.publico_alvo
        .map((p) => (!!p.padrao_serie_id ? p.padrao_serie.descricao : undefined))
        .filter((p) => p),
    );
  }

  public get targetEntities(): string[] {
    return uniq(
      this.data.application.publico_alvo.map((p) => (!!p.entidade_id ? p.entidade.nome : undefined)).filter((p) => p),
    );
  }

  private changeStatus(status) {
    this._assessmentApplicationService
      .updateStatus(this.data.application.uuid, status)
      .pipe(take(1), safeEmpty())
      .subscribe(() => this._notificationService.success('geral.sucesso'));
  }
}
