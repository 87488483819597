import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LevelEducationService } from '@app/core/services';
import { notNull, selectedEntityActive } from '@app/shared/utils';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { head } from 'lodash';
import { BehaviorSubject, Observable, Subject, combineLatest, map, switchMap, takeUntil } from 'rxjs';
import { TypeBook } from '../../../modules/educational-materials/utils/type-book.model';

@Component({
  selector: 'app-select-level',
  templateUrl: './select-level.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectLevelComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectLevelComponent implements OnDestroy, ControlValueAccessor {
  @Input() public multiple = true;
  @Output() public change = new EventEmitter();
  public data$: Observable<any[]>;
  public readonly type = TypeBook;
  public ctrl = new FormControl([]);
  public countryId$ = new BehaviorSubject<any>(undefined);
  private _destroy$ = new Subject<void>();

  constructor(private store: Store<AppState>, private levelEducationService: LevelEducationService) {
    const activeUser$ = this.store.select(AppSelectors.ActiveUser).pipe(takeUntil(this._destroy$), notNull());
    this.data$ = combineLatest({ countryId: this.countryId$, activeUser: activeUser$ }).pipe(
      switchMap(({ countryId, activeUser }) => {
        const entity = selectedEntityActive(activeUser);
        return this.levelEducationService.index(countryId || activeUser.pais_id, head(entity));
      }),
      map((res) => res?.data || (this.multiple ? [] : null)),
    );
  }

  @Input()
  public set countryId(value: number) {
    this.countryId$.next(value);
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public writeValue(obj: any): void {
    this.ctrl.setValue(obj);
  }

  public registerOnChange(fn: any): void {
    this.changeValue = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.ctrl.disable();
    } else {
      this.ctrl.enable();
    }
  }

  public onFocus() {
    this.onTouched();
  }

  public onChange(value: any) {
    const defaultValue = this.multiple ? [] : undefined;
    this.change.emit(value.id || defaultValue);
    this.changeValue(value.id || defaultValue);
  }

  private onTouched = () => true;
  private changeValue = (_: any) => true;
}
