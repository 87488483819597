import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { TextEditorModule } from '../text-editor/text-editor.module';
import { FileViewerComponent } from './file-viewer.component';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '../../pipes/pipes.module';

@NgModule({
  declarations: [FileViewerComponent],
  imports: [NgxDocViewerModule, CommonModule, TextEditorModule, TranslateModule, PipesModule],
  exports: [FileViewerComponent],
})
export class FileViewerModule {}
