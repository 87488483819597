<div #popoverTop class="relative w-8 h-8 group">
  <div
    app-tooltip
    [tooltip]="tooltip"
    (click)="onOpenPopoverAnnull(popoverTop)"
    class="absolute top-0 right-0 z-10 flex items-center justify-center w-8 h-8 transition-all duration-300 rounded-full"
    [ngClass]="{
      'group-hover:rounded-l-full group-hover:rounded-r-none': !!cancelAnnulment,
      'bg-base-status-warning': type === assessmentAnnulTypeEnum.Question,
      'bg-base-status-danger': type === assessmentAnnulTypeEnum.Answer
    }"
  >
    <svg-icon class="w-4 text-white" src="assets/icons/icon-feedback.svg"></svg-icon>
  </div>
  <div
    *ngIf="cancelAnnulment"
    app-tooltip
    [tooltip]="'gestor-avaliacoes.reverter-anulada' | translate"
    (click)="onQuestAnulado()"
    class="absolute top-0 right-0 z-0 flex items-center justify-center w-8 h-8 transition-all duration-300 bg-gray-500 rounded rounded-r-full opacity-0 cursor-pointer group-hover:-right-8 group-hover:opacity-100"
  >
    <svg-icon class="w-4 text-white" src="assets/icons/icon-arrow-right-return.svg"></svg-icon>
  </div>
</div>
