<app-card class="h-[80vh] w-[80vw] overflow-y-auto">
  <div app-card-header>
    <h1 class="text-lg">
      {{ data.data.title }}
    </h1>
  </div>
  <hr app-divider class="my-5" />
  <div class="h-full overflow-y-auto" app-card-content>
    <app-preview-certificado [data]="data.data"></app-preview-certificado>
  </div>
  <hr app-divider class="my-5" />
  <div app-card-footer class="flex gap-1">
    <button (click)="onClose()" app-button class="bg-red-500 text-theme-contrast">
      {{ 'geral.fechar' | translate }}
    </button>
  </div>
</app-card>
