<ng-container *ngIf="state$ | async as state">
  <app-modal-header class="border-b-2 border-theme-500" [automaticClose]="false" (closeEvent)="onClose()">
    <div class="flex w-full items-center gap-2">
      <svg-icon
        class="w-10 text-theme-500"
        src="assets/icons/icon-gestor-avaliacoes.svg"
        alt="Materiais Didaticos"
      ></svg-icon>

      <h1 class="text-sm font-semibold leading-tight text-gray-800" translate>
        gestor-avaliacoes.e-class
        <br />
        <b class="text-lg text-black" translate>gestor-avaliacoes.prova</b>
      </h1>

      <hr app-modal-divider />

      <div class="flex w-full items-center justify-between">
        <h2 class="text-lg leading-tight text-gray-800">
          {{ state.avaliacao?.titulo }}
          <span
            *ngIf="state.avaliacao.tipo_gerenciada?.id"
            class="rounded-full bg-modules-eclass-200 px-2 text-sm text-black"
          >
            {{ state.avaliacao?.tipo_gerenciada.descricao }}</span
          >
        </h2>
        <span *ngIf="state.extra?.preview" class="rounded-full bg-base-status-info px-5 text-white" translate>
          gestor-avaliacoes.pre-visualizacao
        </span>
      </div>
    </div>
  </app-modal-header>
  <div app-modal-content [ngSwitch]="onShowScreen(state)" class="!h-[75vh] !w-[100vw] !p-0 md:!h-[90vh] md:!w-[98vw]">
    <!-- Estado inicial -->
    <app-screen-start *ngSwitchCase="screen.Init"></app-screen-start>

    <ng-container *ngSwitchCase="screen.Running">
      <app-sidenav-group class="bg-base-whisper-500">
        <ng-template
          app-sidenav-start
          [componentRef]="generalSettingsRef"
          [opened]="true"
          class="rounded-bl-lg !bg-theme-50"
        >
        </ng-template>

        <ng-template app-sidenav-outer>
          <div class="p-4">
            <app-modal-execution-questions class="flex flex-col"></app-modal-execution-questions>
          </div>

          <div class="hiddem w-full justify-center px-5 pb-5 md:flex">
            <button
              [disabled]="onDisableSave(state)"
              app-flat-button
              (click)="onConfirmFinalize()"
              class="w-full bg-theme-950 px-5 uppercase text-white"
              type="button"
              translate
            >
              gestor-avaliacoes.finalizar
            </button>
          </div>
        </ng-template>
      </app-sidenav-group>

      <div class="flex w-full justify-end rounded-b-2xl bg-white px-6 pt-6 shadow-up md:hidden md:pt-9">
        <div class="flex justify-end gap-4">
          <div *ngIf="state.finish_until" class="flex w-full !flex-row items-center">
            <label app-suffix class="ml-2 !pb-0">
              Finalizar até: {{ state.finish_until | date : 'dd/MM/YYYY HH:mm:ss' }}
            </label>
          </div>
          <button
            app-flat-button
            *ngIf="state.is_preview; else buttonSaveTemplate"
            (click)="onClose()"
            class="bg-theme-950 px-5 uppercase text-white"
            type="button"
          >
            Fechar
          </button>
          <ng-template #buttonSaveTemplate>
            <button
              [disabled]="onDisableSave(state)"
              app-flat-button
              (click)="onConfirmFinalize()"
              class="bg-theme-950 px-5 uppercase text-white"
              type="button"
            >
              Finalizar
            </button>
          </ng-template>
        </div>
      </div>
    </ng-container>

    <!-- Estado Finalizar -->
    <app-screen-finalize *ngSwitchCase="screen.Finalize"></app-screen-finalize>

    <!-- Estado Aguardando -->
    <app-screen-wait *ngSwitchCase="screen.Wait"> </app-screen-wait>

    <!-- Estado Aprovado -->
    <app-screen-approved *ngSwitchCase="screen.Approved"> </app-screen-approved>

    <!-- Estado Reprovado -->
    <app-screen-failed *ngSwitchCase="screen.Failed"></app-screen-failed>
  </div>
</ng-container>
