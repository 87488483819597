import { AppActions } from '@app/store';
import { AppState } from '@app/store/app.state';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';

enum LoginDomain {
  EducacaoAdventista = 'https://login.educacaoadventista.org.br',
  EaPortal = 'https://login.eaportal.org',
  SistemaInterativo = 'https://login.sistemainterativo.com.br',
  TesteCpb = 'https://login.testecpb.com.br',
  localhost = 'http://localhost',
  Cpbedu = 'https://login.cpbedu.me',
}

interface Resolver {
  accept: (url: string) => boolean;
  url: string;
}
type LoginResolver = {
  [key in LoginDomain]?: Resolver;
};

function localhostURL(): string {
  if (environment.env === 'local') {
    return LoginDomain.localhost;
  }
  if (environment.env === 'dev') {
    return LoginDomain.TesteCpb;
  }
  return LoginDomain.EducacaoAdventista;
}

const LOGIN_RESOLVERS: LoginResolver = {
  [LoginDomain.EducacaoAdventista]: {
    accept: (url: string) => url.includes('educacaoadventista'),
    url: LoginDomain.EducacaoAdventista,
  },
  [LoginDomain.EaPortal]: {
    accept: (url: string) => url.includes('eaportal'),
    url: LoginDomain.EaPortal,
  },
  [LoginDomain.SistemaInterativo]: {
    accept: (url: string) => url.includes('sistemainterativo'),
    url: LoginDomain.SistemaInterativo,
  },
  [LoginDomain.TesteCpb]: {
    accept: (url: string) => url.includes('testecpb'),
    url: LoginDomain.TesteCpb,
  },
  [LoginDomain.localhost]: {
    accept: (url: string) => url.includes('localhost'),
    url: localhostURL(),
  },
};

function navigateToLogin(store: Store<AppState>) {
  store.dispatch(AppActions.TokenClearEffect());
  const { hostname, href } = new URL(window.location.href);
  const redirect = window.btoa(href);
  const login = Object.values(LOGIN_RESOLVERS).find((resolver) => resolver.accept(hostname))?.url || LoginDomain.Cpbedu;
  window.location.href = `${login}/logout?l=${redirect}`;
}

export const Login = {
  navigateToLogin,
};
