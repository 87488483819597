import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { isMobile } from '@app/shared/utils/mobile-detector';
import { viewerType } from 'ngx-doc-viewer';
import { BehaviorSubject, Subject } from 'rxjs';

interface DataParams {
  name: string;
  url: string;
  type?: viewerType;
  html?: string;
}

@Component({
  selector: 'app-file-viewer',
  templateUrl: './file-viewer.component.html',
})
export class FileViewerComponent implements OnDestroy {
  @Output() public close = new EventEmitter();

  public data$ = new BehaviorSubject<any>(undefined);
  private _destroy$ = new Subject<void>();
  private _types = {
    png: 'image',
    jpeg: 'image',
    jpg: 'image',
    pdf: 'url',
    doc: 'office',
    docx: 'office',
    txt: 'google',
    xls: 'office',
    xlsx: 'office',
    ppt: 'office',
    pptx: 'office',
    mp3: 'audio',
    mp4: 'iframe',
    m4v: 'iframe',
    mov: 'iframe',
  };

  private _regex = /(?:\.([^.]+))?$/;

  @Input('data') public set setData(data: DataParams) {
    if (!data) {
      return;
    }

    const type = this._types[this._regex.exec(data.name.toLowerCase())[1]];
    this.data$.next({ ...data, type });
  }

  public isMobile(url: string) {
    const mobile = isMobile();
    if (mobile) {
      this.onOpenPdf(url);
    }
    return mobile;
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
    this.data$.complete();
  }

  public onClose() {
    this.close.emit();
  }

  public onOpenPdf(url: string) {
    window.open(url, '_blank');
  }
}
