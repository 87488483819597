<label app-label *ngIf="label">{{ 'materiais-didaticos.disciplina' | translate }}</label>
<ng-select
  app-select
  [placeholder]="label ? ('eclass.selecione' | translate) : ('materiais-didaticos.disciplina' | translate)"
  [multiple]="multiple"
  (change)="onChange($event)"
  (focus)="onFocus()"
  [formControl]="ctrl"
  [notFoundText]="'geral.nao-encontrado' | translate"
  [items]="data$ | async"
  bindLabel="descricao"
  bindValue="id"
>
</ng-select>
