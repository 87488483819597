<app-card class="h-[90vh] w-[80vw]">
  <div app-card-header class="w-full">
    <div class="flex items-center justify-between px-4">
      <h1 class="text-xl font-medium">{{ 'trilhas.estudio.curso.certificado' | translate }}</h1>
      <button
        (click)="onCloseAdd()"
        app-outline-button
        class="border-[#11324b] text-[14px] text-[#00324a] hover:[&:not([disabled])]:bg-[#11324b] hover:[&:not([disabled])]:text-theme-contrast"
        type="button"
      >
        {{ 'trilhas.estudio.curso.adicionar-certificado' | translate }}
      </button>
    </div>
  </div>
  <hr app-divider class="my-5" />
  <div app-card-content class="h-[100vh] w-full overflow-auto">
    <div *ngIf="certificados$ | async as certificados" class="flex flex-wrap">
      <div *ngFor="let certificado of certificados" class="w-full p-4 md:w-1/2 lg:w-1/3 xl:w-1/4">
        <div class="flex h-12 items-center justify-between bg-white px-4 py-4 shadow-md">
          <h3 class="overflow-hidden text-[14px] font-medium" [title]="certificado.titulo">
            {{ certificado.titulo }}
          </h3>
          <button (click)="edit(certificado)" app-icon-button class="shadow-md hover:bg-gray-300">
            <svg-icon class="h-5 w-5 text-theme-900" src="assets/icons/icon-edit.svg"></svg-icon>
          </button>
        </div>
        <div class="m-form" [ngClass]="{ boxMaskCertificado: safeData?.canSelect }">
          <div class="mask-certificado-preview" (click)="onSelect(certificado)">
            <p class="text-lg" translate>{{ safeData.labelCertificado }}</p>
          </div>
          <div class="relative p-3">
            <app-preview-certificado [data]="onFormat(certificado)"></app-preview-certificado>
          </div>
        </div>
      </div>
    </div>
  </div>
  <hr app-divider class="my-5" />
  <div app-card-footer class="flex gap-1">
    <button (click)="onCloseModal()" app-button class="bg-gray-50 text-black">{{ 'geral.fechar' | translate }}</button>
  </div>
</app-card>
