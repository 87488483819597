<div app-modal-content class="h-[90vh] w-[98vw] !p-0">
  <app-sidenav-group class="!bg-base-whisper-500">
    <ng-container *ngIf="aplication$ | async as aplication">
      <ng-container *ngIf="dataStudents$ | async as students">
        <ng-template [opened]="true" [mode]="mode.Over" app-sidenav-start>
          <div class="h-full bg-white p-6">
            <div class="grid w-full p-5">
              <span class="font-bold" translate> gestor-avaliacoes.alunos </span>
              <div class="flex justify-between">
                <span class="font-light" translate> gestor-avaliacoes.pendente-de-correcao </span>
                <app-switch [formControl]="pendingCtrl"></app-switch>
              </div>
            </div>
            <hr app-divider />

            <!-- CARD DOS ALUNOS  -->
            <div class="px-1 pb-16 pt-0">
              <app-card
                *ngFor="let student of students; let index = index"
                [id]="'user_' + student.usuario_id"
                (click)="onSetSelectedUser(student, index)"
                class="mb-1 mt-5 flex !flex-row items-center p-4 !shadow-black/25 hover:bg-base-mantis-200"
                [ngClass]="{
                  ' active:!brightness-85 !bg-base-mantis-100 !shadow-inner !shadow-black/40 !transition-all !duration-300 hover:!brightness-90':
                    student.usuario_id === selectedUser$.value.data.usuario_id &&
                    student.tentativa_uuid === selectedUser$.value.data.tentativa_uuid
                }"
              >
                <div class="w-3/12">
                  <img
                    *ngIf="!student.foto"
                    src="assets/images/empty-avatar.png"
                    class="border-1 h-16 w-max !rounded-full bg-white/80 !p-1 shadow-sm shadow-black/40"
                  />
                  <app-user-photo
                    *ngIf="!!student.foto"
                    class="h-auto !w-max md:!h-16"
                    [height]="'!h-16'"
                    [width]="'!w-max'"
                    [src]="student.foto"
                  ></app-user-photo>
                </div>
                <div *ngIf="!!student.usuario_id; else noStudents" class="ml-5 grid w-full">
                  <span class="font-bold"> {{ student.nome }} </span>
                  <span class="text-gray-500"> {{ student.turma_descricao }} </span>
                </div>
                <ng-template #noStudents>
                  <app-form-field class="px-2">
                    <label app-label translate> gestor-avaliacoes.vincule-aluno </label>
                    <ng-select
                      app-select
                      [formControl]="dataStudentCtrl"
                      [items]="dataStudentFilter$ | async"
                      bindLabel="nome"
                    >
                    </ng-select>
                  </app-form-field>
                </ng-template>
              </app-card>
            </div>
          </div>
        </ng-template>

        <ng-template app-sidenav-outer class="relative">
          <!-- CARD DOS ALUNOS -->
          <ng-container *ngIf="selectedUser$.value?.data as user">
            <div class="flex h-auto gap-4 p-5">
              <app-card class="flex w-full">
                <div class="flex justify-between p-3">
                  <!-- BOTÃO ANTERIOR -->
                  <div class="flex">
                    <button
                      [disabled]="user.index <= 0"
                      (click)="onPreviousUser()"
                      app-icon-button
                      app-tooltip
                      tooltip="Anterior"
                      type="button"
                      class="!rounded-full bg-white"
                    >
                      <svg-icon class="flex h-6" src="assets/icons/icon-arrow-left-fine.svg"></svg-icon>
                    </button>
                  </div>

                  <!-- INFORMAÇÕES DO ALUNO -->
                  <div class="flex px-5 md:items-center">
                    <img
                      *ngIf="!user.foto"
                      src="assets/images/empty-avatar.png"
                      class="border-1 h-24 w-max !rounded-full bg-white/80 !p-1 shadow-sm shadow-black/40"
                    />
                    <app-user-photo
                      *ngIf="!!user.foto"
                      class="!h-24 !w-max"
                      [height]="'!h-24'"
                      [width]="'!w-max'"
                      [src]="user.foto"
                    ></app-user-photo>
                    <div class="grid pl-5">
                      <div class="grid items-center" *ngIf="!!user.usuario_id; else noStudent">
                        <span class="text-md font-bold active:text-base-mantis-500 md:text-lg"> {{ user.nome }} </span>
                        <span *ngIf="user.turma_descricao" class="active:text-base-mantis-500">
                          {{ user.turma_descricao }}
                        </span>
                      </div>
                      <ng-template #noStudent>
                        <span class="text-md font-bold active:text-base-mantis-500 md:text-lg">Sem aluno</span>
                      </ng-template>

                      <div class="flex items-baseline">
                        <ng-container *ngIf="evaluationByWeight; else labelPercentual">
                          <p class="text-[14px]">{{ 'gestor-avaliacoes.nota' | translate }}:</p>
                        </ng-container>
                        <ng-template #labelPercentual>
                          <p class="text-[14px]">{{ 'gestor-avaliacoes.percentual-de-acerto' | translate }}:</p>
                        </ng-template>
                        <span *ngIf="!!user.status; else empty" class="mx-2 text-md font-semibold">
                          {{ user?.nota_calculada | currency : '' : '' : '1.2-2' }}
                          <ng-container *ngIf="!evaluationByWeight">% </ng-container>
                        </span>
                        <ng-template #empty>
                          <span class="pl-2 font-bold">-</span>
                        </ng-template>
                      </div>
                    </div>
                  </div>
                  <!-- BOTÃO PRÓXIMO -->
                  <div class="flex">
                    <button
                      [disabled]="user.index >= students.length - 1"
                      (click)="onNextUser()"
                      app-icon-button
                      app-tooltip
                      tooltip="Próximo"
                      type="button"
                      class="!rounded-full bg-white"
                    >
                      <svg-icon class="flex h-6" src="assets/icons/icon-arrow-right-fine.svg"></svg-icon>
                    </button>
                  </div>
                </div>
              </app-card>
            </div>
          </ng-container>

          <div class="grid h-auto w-full gap-4 p-5 lg:flex">
            <!-- CARD CENTRAL COM A IMAGEM  -->
            <app-card
              class="order-2 flex max-h-[70vh] min-h-[64vh] w-[27rem] overflow-y-auto p-5 shadow-inner shadow-black/40 md:w-full lg:order-1 lg:w-9/12 xl:max-h-[75vh] 2xl:max-h-[78vh]"
            >
              <ng-container *ngIf="dataViewer$ | async as data; else emptyImage">
                <div class="flex justify-center !rounded-lg">
                  <app-file-viewer class="h-full w-full" [data]="data"></app-file-viewer>
                </div>
              </ng-container>

              <ng-template #emptyImage>
                <div class="grid h-auto gap-4 p-5">
                  <app-card>
                    <div app-card-content class="h-[40rem] w-full">
                      <app-jumbotron [showIcon]="false" translate>
                        <svg-icon
                          class="mb-5 w-20 text-gray-300"
                          src="assets/icons/icon-improvement.svg"
                          alt="Materiais Didaticos"
                        ></svg-icon>
                        gestor-avaliacoes.sem-imagem
                      </app-jumbotron>
                    </div>
                  </app-card>
                </div>
              </ng-template>
            </app-card>

            <!-- SEGUNDO CARD  -->
            <app-card class="order-1 w-[27rem] p-5 md:w-full lg:order-2 xl:w-full 2xl:w-6/12">
              <form [formGroup]="form">
                <app-form-field
                  *ngIf="!!aplication.embaralhar_questoes || !!aplication.embaralhar_alternativas"
                  class="w-full"
                >
                  <ng-select
                    app-select
                    [clearable]="false"
                    [searchable]="false"
                    formControlName="modo_rand"
                    (change)="onChange()"
                    [placeholder]="'gestor-avaliacoes.selecione-modelo' | translate"
                  >
                    <ng-option [value]="1">Modelo 1</ng-option>
                    <ng-option [value]="2">Modelo 2</ng-option>
                    <ng-option [value]="3">Modelo 3</ng-option>
                    <ng-option [value]="4">Modelo 4</ng-option>
                  </ng-select>
                </app-form-field>
              </form>

              <div
                class="grid max-h-[64vh] gap-5 overflow-y-auto rounded-lg border-2 p-5 shadow-inner-bg xl:max-h-[71vh]"
              >
                <!-- FAÇA A LOGICA AQUI SELECIONAR O MODELO  -->
                <ng-container *ngIf="aplication.avaliacao.questoes.length > 0; else empty">
                  <!-- FIM DA LOGICA  -->
                  <div
                    *ngFor="let question of aplication.avaliacao.questoes; let index = index"
                    [id]="'question_' + question.uuid"
                  >
                    <ng-container *ngIf="onGetAnswer(question.uuid) as answer">
                      <app-card
                        class="grid w-[22rem] !flex-row items-center rounded-xl p-4 !shadow-black/25 hover:bg-base-mantis-100 md:flex md:w-full xl:w-full"
                      >
                        <div class="flex w-full">
                          <span class="flex w-full gap-2 text-md font-semibold">
                            {{ 'gestor-avaliacoes.questao' | translate }} {{ index + 1 }}
                            <app-badge-annuled-question *ngIf="!!answer.data?.anulado"></app-badge-annuled-question>
                          </span>
                        </div>

                        <div class="flex w-full justify-end pt-5 md:pt-0">
                          <ng-container *ngIf="question.alternativas.length >= 1; else Discurssiva">
                            <div class="flex w-full gap-2 md:justify-end">
                              <span
                                *ngFor="let alternative of question.alternativas; let i = index"
                                (click)="onChangeAlternativa(question.uuid, alternative.uuid)"
                                class="flex cursor-pointer items-center rounded-lg border px-2 py-0 text-[1.2rem] font-medium hover:border-base-mantis-400 hover:text-base-mantis-400"
                                [ngClass]="{
                                  'border-base-mantis-500 text-base-mantis-500': onCorrectOrSociodemographic(
                                    alternative,
                                    question
                                  ),
                                  'border-base-mantis-300 text-base-mantis-300': onIncorrectAndNotSociodemographic(
                                    alternative,
                                    question
                                  ),
                                  'border-red-500 text-red-500 hover:border-red-700 hover:!text-red-700':
                                    onSelectedIncorrectAndNotSociodemographic(alternative, answer, question),
                                  'bg-white shadow-sm shadow-black/40': shouldApplyWhiteBackground(
                                    alternative,
                                    answer,
                                    question
                                  )
                                }"
                              >
                                <svg-icon
                                  *ngIf="
                                    alternative.uuid === answer.data?.alternativa?.uuid &&
                                    !!alternative.alternativa_correta
                                  "
                                  class="mr-2 flex h-3"
                                  src="assets/icons/icon-check.svg"
                                ></svg-icon>
                                <svg-icon
                                  *ngIf="
                                    alternative.uuid === answer.data?.alternativa?.uuid &&
                                    !alternative.alternativa_correta
                                  "
                                  class="mr-2 flex h-3"
                                  src="assets/icons/icon-delete.svg"
                                ></svg-icon>

                                {{ toLetter(i) }}
                              </span>
                            </div>
                          </ng-container>
                          <ng-template #Discurssiva>
                            <div class="flex items-center">
                              <ng-container *ngFor="let scale of scales">
                                <ng-container *ngIf="onCalcNota(question.peso, scale.value) as result">
                                  <div class="flex !items-center bg-gray-200 p-1 first:rounded-l-lg last:rounded-r-lg">
                                    <button
                                      [ngClass]="{
                                        '  shadow-inner shadow-black/40':
                                          (result?.nota | currency) === (answer?.data?.nota | currency)
                                      }"
                                      (click)="onSaveNota(question.uuid, result?.nota)"
                                      [id]="'btn_' + scale.shortcut"
                                      class="flex p-1 px-3 transition-all duration-300 hover:bg-white/80 md:px-5"
                                    >
                                      <span
                                        *ngIf="
                                          aplication.avaliacao.peso_tipo === assessmentWeightType.Peso;
                                          else percent
                                        "
                                      >
                                        {{ result.nota | currency : '' : '' : '1.2-2' }}
                                      </span>
                                      <ng-template #percent>
                                        {{ scale.value | percent }}
                                      </ng-template>
                                    </button>
                                  </div>
                                </ng-container>
                              </ng-container>
                            </div>
                          </ng-template>
                          <button
                            #popoverTop
                            app-outline-button
                            type="button"
                            class="top-[-1.6rem] !border-none p-2 text-black md:top-[0rem] hover:[&:not([disabled])]:bg-black hover:[&:not([disabled])]:text-theme-contrast"
                          >
                            <svg-icon
                              (click)="onOpenPopover(popoverTop, [strategy.LEFT], question)"
                              class="flex h-5"
                              src="assets/icons/icon-three-dots.svg"
                            ></svg-icon>
                          </button>
                        </div>
                      </app-card>
                    </ng-container>
                  </div>
                </ng-container>
              </div>

              <ng-template #empty>
                <div class="grid h-auto gap-4 p-5">
                  <app-card>
                    <div app-card-content class="h-[200px]">
                      <app-jumbotron [showIcon]="false" translate>
                        <svg-icon
                          class="mb-5 w-10 text-gray-300"
                          src="assets/icons/icon-info-rounded.svg"
                          alt="Materiais Didaticos"
                        >
                        </svg-icon>
                        gestor-avaliacoes.selecione-modelo
                      </app-jumbotron>
                    </div>
                  </app-card>
                </div>
              </ng-template>
            </app-card>
          </div>
        </ng-template>
      </ng-container>
    </ng-container>
  </app-sidenav-group>
</div>
