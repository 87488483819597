<div *ngIf="state$ | async as state" class="h-full bg-white p-5">
  <h2 class="mb-4 text-[14px] font-semibold leading-tight text-gray-800">{{ state.avaliacao.titulo }}</h2>
  <hr app-divider class="my-5" />
  <div class="grid h-auto gap-5">
    <ng-container *ngIf="state.aplicacao.data_inicio">
      <span class="font-semibold" translate>gestor-avaliacoes.mais-informacoes</span>
      <table app-table class="w-full border-separate border-spacing-0.5 border-spacing-y-0.5">
        <tbody app-table-body>
          <tr *ngIf="state.aplicacao.data_inicio">
            <td app-table-cell translate>gestor-avaliacoes.periodo-aplicacao</td>
            <td app-table-cell>
              {{ state.aplicacao.data_inicio | date : 'short' }}
              <ng-container
                *ngIf="
                  state.aplicacao.data_fim && state.aplicacao.modo_aplicacao !== assessmentApplicationMode.Impressa
                "
              >
                até {{ state.aplicacao.data_fim | date : 'short' }}
              </ng-container>
            </td>
          </tr>
          <tr *ngIf="state.started_at">
            <td app-table-cell translate>gestor-avaliacoes.iniciado-em</td>
            <td app-table-cell>
              {{ state.started_at | date : 'dd/MM/yyyy HH:mm:ss' }}
            </td>
          </tr>
          <tr *ngIf="state.aplicacao.tempo_maximo">
            <td app-table-cell translate>gestor-avaliacoes.duracao-maxima</td>
            <td app-table-cell>{{ state.aplicacao.tempo_maximo }} m</td>
          </tr>
          <tr *ngIf="state.finish_until">
            <td app-table-cell>Finalizar até</td>
            <td app-table-cell>
              {{ state.finish_until | date : 'dd/MM/yyyy HH:mm:ss' }}
            </td>
          </tr>
        </tbody>
      </table>
      <hr app-divider />
    </ng-container>

    <div>
      <h2 class="text-[14px] font-semibold leading-tight text-gray-800" translate>gestor-avaliacoes.resumo</h2>
      <ul class="overflow-y-auto">
        <li class="flex flex-wrap gap-1 py-4">
          <div
            *ngFor="let question of state.questoes; let i = index"
            (click)="onChangeQuestion('question_' + i)"
            [ngClass]="{ '!bg-base-status-info !text-white': isAnswered(question.resposta) }"
            class="h-8 w-8 cursor-pointer rounded-full border border-solid border-base-status-info text-center text-[14px] font-semibold text-base-status-info shadow-md shadow-black/25"
          >
            {{ i + 1 }}
          </div>
        </li>
      </ul>
    </div>

    <div *ngIf="!!state.avaliacao?.orientacoes" class="grid h-auto">
      <app-accordion>
        <app-accordion-item [expanded]="true">
          <div app-accordion-header class="flex font-bold" translate>gestor-avaliacoes.orientacoes-execucao</div>
          <div app-accordion-content class="p-5">
            <app-text-editor [value]="state.avaliacao.orientacoes" [readOnly]="true" class="block"></app-text-editor>
          </div>
        </app-accordion-item>
      </app-accordion>
    </div>

    <div class="hiddem w-full justify-center pb-10 md:flex">
      <button
        [disabled]="onDisableSave(state)"
        app-flat-button
        (click)="onConfirmFinalize()"
        class="w-full bg-theme-950 uppercase text-white"
        type="button"
        translate
      >
        gestor-avaliacoes.finalizar
      </button>
    </div>
  </div>
</div>
<ng-template #emptyTemplate> - </ng-template>
