import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, DestroyRef, Inject, OnDestroy } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { AlertService } from '@app/core';
import { AssessmentOrigin } from '@app/core/models';
import { notNull } from '@app/shared';
import { Store } from '@ngrx/store';
import { cloneDeep, isEqual } from 'lodash';
import { BehaviorSubject, distinctUntilChanged, filter, take, tap } from 'rxjs';
import { ModalAssessmentExecutionService } from '../modal-assessment-execution/modal-assessment-execution.service';
import { ModalNewAssessmentGeneralSettingsComponent } from './components/modal-new-assessment-general-settings/modal-new-assessment-general-settings.component';
import { ModalNewAssessmentService } from './modal-new-assessment.service';
import { ModalNewAssessmentActions, ModalNewAssessmentSelectors } from './store';
import { ModalNewAssessmentData } from './store/modal-new-assessment.actions';
import { ModalNewAssessmentState } from './store/modal-new-assessment.state';

@Component({
  selector: 'app-modal-new-assessment',
  templateUrl: './modal-new-assessment.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalNewAssessmentComponent implements OnDestroy {
  public readonly generalSettingsRef = ModalNewAssessmentGeneralSettingsComponent;
  public state$ = new BehaviorSubject<ModalNewAssessmentState>(undefined);
  public tentativaCount = 0;
  public statusCtrl = new FormControl();
  private closeModal: boolean;
  public isLoading: boolean = false;
  public loading = new BehaviorSubject<boolean>(true);

  constructor(
    @Inject(DIALOG_DATA) public data: ModalNewAssessmentData,
    private _store: Store<ModalNewAssessmentState>,
    private _modalNewAssessmentService: ModalNewAssessmentService,
    private _dialogRef: DialogRef,
    private _alertService: AlertService,
    private _destroyRef: DestroyRef,
    private _modalAssessmentExecutionService: ModalAssessmentExecutionService,
  ) {
    this._store
      .select(ModalNewAssessmentSelectors.selectState)
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((state) => {
        this.state$.next(state);
        if (!state.hasChanges && this.isLoading) {
          this.isLoading = false;
        }
        this.statusCtrl.patchValue(state?.aplicacao?.status || false, { emitEvent: false });
      });

    this._store
      .select(ModalNewAssessmentSelectors.selectAttemptCount)
      .pipe(takeUntilDestroyed(_destroyRef), notNull())
      .subscribe(this.checkInitialization);

    this.statusCtrl.valueChanges
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((status) =>
        this._store.dispatch(ModalNewAssessmentActions.UpdateStateReducer({ value: { aplicacao: { status } } })),
      );

    this._store
      .select(ModalNewAssessmentSelectors.selectAssessmentOrigin)
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        distinctUntilChanged(isEqual),
        filter((origin) => origin === AssessmentOrigin.Trilha),
        take(1),
      )
      .subscribe(() =>
        setTimeout(
          () =>
            this._store.dispatch(
              ModalNewAssessmentActions.UpdateStateReducer({
                value: { aplicacao: { status: true }, hasChanges: false },
              }),
            ),
          1000,
        ),
      );

    this._store.dispatch(ModalNewAssessmentActions.InitDataEffect(this.data));

    if (!!this.data?.aplicacao_uuid) {
      this._store
        .select(ModalNewAssessmentSelectors.selectState)
        .pipe(
          takeUntilDestroyed(this._destroyRef),
          distinctUntilChanged(isEqual),
          filter((state) => !!state.aplicacao?.uuid || state.extra?.duplicate),
          tap(() => this.loading.next(false)),
        )
        .subscribe();
    } else {
      this.loading.next(false);
    }
  }

  public onCloseModal(closeModal: any): void {
    this.closeModal = closeModal;
  }

  public get isTrailOrigin(): boolean {
    return this.state$.value.avaliacao?.origem === AssessmentOrigin.Trilha;
  }

  public ngOnDestroy() {
    this.state$.complete();
    this._store.dispatch(ModalNewAssessmentActions.ClearStageReducer());
    this._modalNewAssessmentService.clearCheckers();
  }

  public onSave() {
    if (this.state$.value.hasChanges && this._modalNewAssessmentService.isValid()) {
      this.isLoading = true;

      if (this.tentativaCount > 0) {
        this._alertService
          .confirm({
            title: 'geral.atencao',
            message: 'Esta avaliação já possui respostas de alunos. Deseja salvar a avaliação?',
          })
          .pipe(take(1), notNull())
          .subscribe(() => this.save());
      } else {
        this.save();
      }

      return;
    }

    this._store.dispatch(ModalNewAssessmentActions.UpdateStateReducer({ value: { pressSubmit: true } }));
  }

  private save() {
    this._store.dispatch(ModalNewAssessmentActions.UpdateStateEffect({ value: this.state$.value }));
    if (!!this.closeModal) {
      // Time para esperar salvar no banco, e não fazer varias requisição no banco
      setTimeout(() => this.close(), 2800);
    }
  }

  public onClose() {
    if (!!this.state$.value.hasChanges) {
      this._alertService
        .confirm({ message: 'Tem certeza que deseja sair? Todas as alterações serão canceladas.' })
        .pipe(take(1), notNull())
        .subscribe(() => this.close());
    } else {
      this.close();
    }
  }

  public onPreviewAssessment(aplicationUuid: string) {
    this._modalAssessmentExecutionService.open(AssessmentOrigin.GDA, {
      data: { aplicacao_uuid: aplicationUuid },
      extra: { preview: true },
    });
  }

  public onStopPropagation(event) {
    event.stopPropagation();
  }

  private close() {
    const state = cloneDeep(this.state$.value);
    this._store.dispatch(ModalNewAssessmentActions.ClearStageReducer());
    this._modalNewAssessmentService.clearCheckers();
    this._dialogRef.close(state);
  }

  private checkInitialization = (tentativaCount) => {
    this.tentativaCount = tentativaCount || 0;
    if (this.tentativaCount > 0) {
      this._alertService
        .confirm({
          title: 'geral.atencao',
          message: 'gestor-avaliacoes.avaliacao-alguns-alunos',
        })
        .pipe(filter((result) => !result))
        .subscribe(() => {
          this.state$.next({ ...this.state$.value, hasChanges: false });
          this.onClose();
        });
    }
  };
}
