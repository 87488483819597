import { CdkAccordionModule } from '@angular/cdk/accordion';
import { DialogModule } from '@angular/cdk/dialog';
import { LayoutModule } from '@angular/cdk/layout';
import { CdkMenu, CdkMenuItem, CdkMenuTrigger } from '@angular/cdk/menu';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ImgFallbackModule } from 'ngx-img-fallback';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { ACCORDION } from './components/accordion';
import { AccordionOptionComponent } from './components/accordion-option/accordion-option.component';
import { BadgeDirective } from './components/badge/badge.directive';
import { BUTTONS } from './components/button';
import { CARD } from './components/cards';
import { CardComponent } from './components/cards/card.component';
import { DATEPICKER } from './components/datepicker';
import { DividerDirective } from './components/divider/divider.directive';
import { FORMS } from './components/form-field';
import { ErrorDirective } from './components/form-field/error.directive';
import { InputDirective } from './components/form-field/input.directive';
import { LabelDirective } from './components/form-field/label.directive';
import { HORINZONTAL_SCROOL } from './components/horizontal-scroll';
import { IconDirective } from './components/icon/icon.directive';
import { JumbotronComponent } from './components/jumbotron/jumbotron.component';
import { MODAL } from './components/modal';
import { POPOVER, Popover } from './components/popover';
import { RADIO } from './components/radio';
import { SIDENAV } from './components/sidenav';
import { SkeletonComponent } from './components/skeleton/skeleton.component';
import { SwitchComponent } from './components/switch/switch.component';
import { TAB } from './components/tab';
import { TABLE } from './components/table';
import { TOOLTIP } from './components/tooltip';
import { DateTranslatePipe } from './pipes/date-translate.pipe';

const SHARED = [
  CommonModule,
  TranslateModule,
  NgxMaskDirective,
  NgxMaskPipe,
  ImgFallbackModule,
  FormsModule,
  ReactiveFormsModule,
  NgSelectModule,
  NgOptionHighlightModule,
  DialogModule,
  CdkMenu,
  CdkMenuItem,
  CdkMenuTrigger,
  LayoutModule,
  PortalModule,
];

@NgModule({
  declarations: [
    ...CARD,
    ...BUTTONS,
    ...FORMS,
    ...ACCORDION,
    ...MODAL,
    ...TAB,
    ...HORINZONTAL_SCROOL,
    ...TABLE,
    ...RADIO,
    ...DATEPICKER,
    ...POPOVER,
    ...SIDENAV,
    ...TOOLTIP,
    DividerDirective,
    IconDirective,
    LabelDirective,
    InputDirective,
    ErrorDirective,
    SwitchComponent,
    BadgeDirective,
    JumbotronComponent,
    SkeletonComponent,
    AccordionOptionComponent,
    DateTranslatePipe,
  ],
  providers: [provideNgxMask(), Popover, DatePipe],
  imports: [
    ...SHARED,
    OverlayModule,
    CdkAccordionModule,
    CdkMenu,
    CdkMenuItem,
    CdkMenuTrigger,
    AngularSvgIconModule.forRoot(),
    NgxWebstorageModule.forRoot({ prefix: 'portal', separator: '-' }),
  ],
  exports: [
    ...SHARED,
    ...CARD,
    ...BUTTONS,
    ...FORMS,
    ...ACCORDION,
    ...MODAL,
    ...TAB,
    ...HORINZONTAL_SCROOL,
    ...TABLE,
    ...RADIO,
    ...DATEPICKER,
    ...POPOVER,
    ...SIDENAV,
    ...TOOLTIP,
    DividerDirective,
    NgxWebstorageModule,
    CardComponent,
    IconDirective,
    SwitchComponent,
    AngularSvgIconModule,
    BadgeDirective,
    JumbotronComponent,
    AccordionOptionComponent,
    SkeletonComponent,
    DateTranslatePipe,
  ],
})
export class TemplateModule {}
