import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService, Resp } from './api.service';
import { portalConst } from '@app/config';

@Injectable({
  providedIn: 'root',
})
export class CategoriesService {
  public readonly baseUrl = `${portalConst.api.categories}/v1/`;
  constructor(private api: ApiService) {}

  public topics(nivel_id?: number, padrao_serie_id?: number, padrao_disciplina_id?: number): Observable<any> {
    return this.api.get(`${this.baseUrl}topics`, { nivel_id, padrao_serie_id, padrao_disciplina_id });
  }

  public categoriesTree(
    matrix_id?: number,
    nivel_id?: number,
    grade_id?: number,
    padrao_serie_id?: number,
    padrao_disciplina_id?: number,
    term?: string,
    codeIsNotNull?: boolean,
  ): Observable<any> {
    return this.api.get(`${this.baseUrl}categories/tree`, {
      matrix_id,
      nivel_id,
      grade_id,
      padrao_serie_id,
      padrao_disciplina_id,
      term,
      codeIsNotNull,
    });
  }

  public matrixContent(padrao_disciplina_id?: number): Observable<Resp<any[]>> {
    return this.api.get(`${this.baseUrl}categories/matrix-content/tree`, { padrao_disciplina_id });
  }

  public getMatrices(publishingId: number, stageId?: number): Observable<any> {
    return this.api.get(`${this.baseUrl}matrix`, {
      publishing_id: publishingId,
      except_type: 2,
      except_matrix_content: 1,
      stage_id: stageId,
    });
  }

  public getGrades(publishingId: number, stageId?: number): Observable<any> {
    return this.api.get(`${this.baseUrl}grade`, { publishing_id: publishingId, stage_id: stageId });
  }

  public getComponents(publishingId: number, stageId?: number, gradeId?: number): Observable<any> {
    return this.api.get(`${this.baseUrl}component`, {
      publishing_id: publishingId,
      stage_id: stageId,
      grade_id: gradeId,
    });
  }

  public getcategoriesByIdSumary(id: number): Observable<Resp<any>> {
    return this.api.get<any>(`${this.baseUrl}categories/${id}/tree`);
  }
}
