import { DIALOG_DATA } from '@angular/cdk/dialog';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AssessmentApplicationService } from '@app/core';
import { SCORE_CONFIG } from '@app/modules/eclass/components/modal-daily/base';
import { safeEmptyList } from '@app/shared';
import { clone, first } from 'lodash';
import { Observable } from 'rxjs';

enum ExportType {
  Tct,
  Dicotomizado,
  GabaritoStudents,
}

@Component({
  selector: 'app-modal-export-file',
  templateUrl: './modal-export-file.component.html',
})
export class ModalExportFileComponent {
  public form: FormGroup;
  public applications$: Observable<any>;
  public scoreConfig = {
    ...clone(SCORE_CONFIG),
    suffix: '%',
    allowZero: false,
    precision: 0,
  };

  public options = [
    { id: ExportType.Tct, title: 'TCT' },
    { id: ExportType.Dicotomizado, title: 'Alunos (dicotomizado)' },
    { id: ExportType.GabaritoStudents, title: 'Gabarito' },
  ];

  private readonly QUERY = {
    [ExportType.Tct]: (data: any) => this._assessmentApplicationService.exportTCT(this._applicationUuid, data),
    [ExportType.Dicotomizado]: (data: any) =>
      this._assessmentApplicationService.exportAttempStudents(this._applicationUuid, data),
    [ExportType.GabaritoStudents]: (data: any) =>
      this._assessmentApplicationService.exportGabaritoStudents(this._applicationUuid, data),
  };

  constructor(
    @Inject(DIALOG_DATA) private _applicationUuid: string,
    private _assessmentApplicationService: AssessmentApplicationService,
    private _formBuilder: FormBuilder,
  ) {
    this.form = this._formBuilder.group({
      export_type: [ExportType.Dicotomizado, Validators.required],
      uuids: [[this._applicationUuid], Validators.required],
      min_fill: 75,
    });

    this.applications$ = this._assessmentApplicationService.index().pipe(safeEmptyList());
  }

  public onExport() {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    const baseUuid = first(this.form.value.uuids);
    const data = {
      min_fill: this.form.value.min_fill,
      extra_uuids: undefined,
    };

    if (this.form.value.uuids.some((uuid) => uuid !== baseUuid)) {
      data.extra_uuids = this.form.value.uuids.filter((uuid) => uuid !== baseUuid);
    }

    this.QUERY[this.form.value.export_type](data);
  }
}
